import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { KeyOption } from "match-sorter";
import { useState } from "react";
import { Checkbox } from "~/components/inputs/checkbox";
import { useComboboxSelection } from "~/components/inputs/combobox";
import { TextInput } from "~/components/inputs/text";
import type { EditableMultiOption } from "~/types/forms/multi-option";
type MultipleChoiceProps<Option extends EditableMultiOption> = {
  readOnly?: boolean;
  disabled?: boolean;
  listClassName?: string;
  options: Option[];
  keys: readonly KeyOption<Option>[];
  isError?: boolean;
  value: Option[];
  onChange: (value: Option[]) => void;
  isMultipleChoice?: boolean;
};
export const MultipleChoice = <Option extends EditableMultiOption,>(props: MultipleChoiceProps<Option>) => {
  if (props.readOnly) {
    return <ReadOnlyMultipleChoice {...props} />;
  }
  return <InputMultipleChoice {...props} data-sentry-element="InputMultipleChoice" data-sentry-component="MultipleChoice" data-sentry-source-file="multiple-choice.tsx" />;
};
function ReadOnlyMultipleChoice<Option extends EditableMultiOption>({
  readOnly,
  options,
  value
}: MultipleChoiceProps<Option>) {
  const optionsShown = readOnly ? value.map(val => {
    const option = options.find(option => option.id === val.id);
    return {
      label: val?.label,
      id: val.id,
      editable: option?.editable
    };
  }).sort((a, b) => (a.editable ? 1 : 0) - (b.editable ? 1 : 0)) : options;
  if (optionsShown.length === 0) return <span className={clsx("font-normal")}>/</span>;
  return <ul className="flex list-disc flex-col pl-2" data-sentry-component="ReadOnlyMultipleChoice" data-sentry-source-file="multiple-choice.tsx">
      {optionsShown.map((option, index) => {
      return <li key={index} className="flex items-center gap-2 font-normal">
            <ArrowRightIcon className="text-neutral-900 h-4 w-4" />
            {option.editable ? <>
                <span>Autre:</span> {option.label}
              </> : option.label}
          </li>;
    })}
    </ul>;
}
type MultipleChoiceAnswerProps<Option extends EditableMultiOption> = {
  option: Option;
  isSelected: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  isMultipleChoice?: boolean;
  isError?: boolean;
  value: Option[];
  onChange: (value: Option[]) => void;
};
function MultipleChoiceAnswer<Option extends EditableMultiOption>({
  option,
  isSelected,
  readOnly,
  disabled,
  isMultipleChoice = false,
  isError,
  value,
  onChange
}: MultipleChoiceAnswerProps<Option>) {
  const {
    selectedItems,
    addSelectedItem,
    removeSelectedItem,
    getDropdownProps
  } = useComboboxSelection({
    selectedItems: value,
    onValueChange: selectedOptions => onChange(selectedOptions),
    multiple: isMultipleChoice
  });
  const [hover, setHover] = useState(false);
  return <div className="group relative flex items-center py-px" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} data-sentry-component="MultipleChoiceAnswer" data-sentry-source-file="multiple-choice.tsx">
      <Checkbox {...getDropdownProps()} name={option.id} id={option.id.toString()} readOnly className="mr-2" isError={isError} checked={isSelected} disabled={disabled} rounded={!isMultipleChoice} onChange={e => {
      if (e.target.checked) {
        addSelectedItem(option);
      } else {
        const item = selectedItems.find(({
          id
        }) => id === option.id);
        if (!item) throw new Error("Item not found");
        removeSelectedItem(item);
      }
    }} data-sentry-element="Checkbox" data-sentry-source-file="multiple-choice.tsx" />
      <label htmlFor={option.id.toString()} className={clsx("font-normal transition-colors", {
      "text-neutral-600": disabled,
      "text-neutral-900": !disabled && !readOnly && (isSelected || hover),
      "text-neutral-700": !disabled && !isSelected || readOnly
    })}>
        {option.label}
      </label>
    </div>;
}
type MultipleChoiceEditableAnswerProps<Option extends EditableMultiOption> = {
  option: Option;
  isSelected: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  isMultipleChoice?: boolean;
  isError?: boolean;
  value: Option[];
  onChange: (value: Option[]) => void;
};
function MultipleChoiceEditableAnswer<Option extends EditableMultiOption>({
  option,
  isSelected,
  readOnly,
  disabled,
  isMultipleChoice = false,
  isError,
  value,
  onChange
}: MultipleChoiceEditableAnswerProps<Option>) {
  const {
    selectedItems,
    addSelectedItem,
    removeSelectedItem,
    getDropdownProps
  } = useComboboxSelection({
    selectedItems: value,
    onValueChange: selectedOptions => onChange(selectedOptions),
    multiple: isMultipleChoice
  });
  return <div className="group relative flex items-center py-px" data-sentry-component="MultipleChoiceEditableAnswer" data-sentry-source-file="multiple-choice.tsx">
      <Checkbox {...getDropdownProps()} name={option.id} id={option.id.toString()} readOnly className="mr-2" isError={isError} checked={isSelected} disabled={disabled} rounded={!isMultipleChoice} onChange={e => {
      if (e.target.checked) {
        addSelectedItem(option);
      } else {
        const item = value.find(({
          id
        }) => id === option.id);
        if (!item) throw new Error("Item not found");
        removeSelectedItem(item);
      }
    }} data-sentry-element="Checkbox" data-sentry-source-file="multiple-choice.tsx" />
      <div className="flex gap-2 items-center">
        <label htmlFor={option.id.toString()} className="font-normal transition-colors text-neutral-700">
          Autre:
        </label>
        <TextInput id={option.id.toString()} isError={isError} value={value.find(val => val.id === option.id)?.label || ""} placeholder="Indiquer ici une autre option" readOnly={readOnly} onFocus={() => {
        if (!selectedItems.some(selectedItem => selectedItem.id === option.id)) {
          addSelectedItem(option);
        }
      }} onChange={e => {
        const newOption = {
          ...option,
          label: e.target.value
        };
        onChange(selectedItems.map(option => option.id === newOption.id ? newOption : option));
      }} className="w-72 border-none bg-transparent focus:outline-none" data-sentry-element="TextInput" data-sentry-source-file="multiple-choice.tsx" />
      </div>
    </div>;
}
function InputMultipleChoice<Option extends EditableMultiOption>({
  readOnly,
  listClassName,
  options,
  isError,
  value,
  onChange,
  disabled,
  isMultipleChoice = false
}: MultipleChoiceProps<Option>) {
  const {
    selectedItems,
    getDropdownProps
  } = useComboboxSelection({
    selectedItems: value,
    onValueChange: selectedOptions => onChange(selectedOptions),
    multiple: isMultipleChoice
  });
  const optionsShown = readOnly ? options.filter(option => !selectedItems.some(selectedItem => selectedItem.id === option.id)) : options;
  return <div className="@container" data-sentry-component="InputMultipleChoice" data-sentry-source-file="multiple-choice.tsx">
      <ul {...getDropdownProps()} className={listClassName}>
        {optionsShown.map((option, index) => {
        const isSelected = selectedItems.some(selectedItem => selectedItem.id === option.id);
        if (option.editable) {
          return <li key={index}>
                <MultipleChoiceEditableAnswer option={option} isSelected={isSelected} readOnly={readOnly} disabled={disabled} isMultipleChoice={isMultipleChoice} isError={isError} value={value} onChange={onChange} />
              </li>;
        } else {
          return <li key={index}>
                <MultipleChoiceAnswer option={option} isSelected={isSelected} readOnly={readOnly} disabled={disabled} isMultipleChoice={isMultipleChoice} isError={isError} value={value} onChange={onChange} />
              </li>;
        }
      })}
      </ul>
    </div>;
}