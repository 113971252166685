import clsx from "clsx";
import { forwardRef } from "react";
import type { InputAttributes, NumericFormatProps } from "react-number-format";
import { NumericFormat } from "react-number-format";
import { emptyInputClassName } from "~/components/inputs/utils";
import { Typography } from "~/components/typography";
import { currencyOptions } from "~/constants/inputs";
import type { InputProps } from "~/types/forms/inputs";
import { getNumericPlaceholder } from "~/utils/input";
type MoneyInputProps = NumericFormatProps<InputAttributes> & InputProps & {
  currency?: string;
};
export const MoneyInput = forwardRef<HTMLInputElement, MoneyInputProps & {
  containerClassName?: string;
}>(function RHFLabeledMoneyInput({
  isError,
  unstyled = false,
  readOnly,
  className,
  decimalScale = 2,
  decimalSeparator = ",",
  thousandSeparator = ".",
  currency,
  placeholder,
  value,
  containerClassName,
  ...props
}, ref) {
  return (
    // There shouldn't be any need to use the container in this component but I didn't want to remove it in case it breaks something else
    <div className={clsx("flex items-center space-x-2 relative", containerClassName)}>
      <NumericFormat getInputRef={ref} readOnly={readOnly} className={clsx("w-[14ch]", readOnly ? [!value && emptyInputClassName, "border-none p-1"] : !unstyled && ["rounded-[3px] border px-2 py-2 border-collapse text-neutral-900 placeholder:text-neutral-600 placeholder:italic focus:border-neutral-500 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:border-neutral-300 disabled:bg-neutral-100 disabled:text-neutral-300 disabled:placeholder:text-neutral-500", "data-[locked=true]:border-neutral-300 data-[locked=true]:bg-neutral-50 data-[locked=true]:text-neutral-700", isError ? "border-error" : "border-grey-blue-80"], className)} allowNegative={false} allowLeadingZeros={false} decimalScale={decimalScale} decimalSeparator={decimalSeparator} thousandSeparator={thousandSeparator} placeholder={placeholder || getNumericPlaceholder(decimalScale, decimalSeparator)} value={readOnly && value == null ? "/" : value} {...props} />
      {currency && <Typography.note className="px-2 bg-transparent absolute inset-y-0 right-1 flex items-center justify-center">
          {currencyOptions.find(currencyOption => currencyOption.id === currency)?.label}
        </Typography.note>}
    </div>
  );
});
export const MoneyInputPrimitive = forwardRef<HTMLInputElement, MoneyInputProps & {
  containerClassName?: string;
}>(function RHFLabeledMoneyInput({
  // isError,
  // unstyled = false,
  readOnly,
  decimalScale = 2,
  decimalSeparator = ",",
  thousandSeparator = ".",
  currency,
  placeholder,
  value,
  containerClassName,
  ...props
}, ref) {
  return (
    // There shouldn't be any need to use the container in this component but I didn't want to remove it in case it breaks something else
    <div className={clsx("relative", containerClassName)}>
      <NumericFormat getInputRef={ref} readOnly={readOnly} allowNegative={false} allowLeadingZeros={false} decimalScale={decimalScale} decimalSeparator={decimalSeparator} thousandSeparator={thousandSeparator} placeholder={placeholder || getNumericPlaceholder(decimalScale, decimalSeparator)} value={readOnly && value == null ? "/" : value} {...props} />
      {currency && <Typography.note className="px-2 bg-transparent absolute insety-0 right-1 top-1/2 -translate-y-1/2 flex items-center justify-center">
          {currencyOptions.find(currencyOption => currencyOption.id === currency)?.label}
        </Typography.note>}
    </div>
  );
});